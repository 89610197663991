<template>
  <div class="content">
    <div class="flex">
      <div>
        <Menu />
      </div>
      <div class="w-full relative">
        <div
          class="flex items-center justify-between px-6 pt-6 pb-2 border-b-2 border-nColorTextInput"
        >
          <div class="flex items-center gap-2">
            <div class="font-InterExtraBold text-3xl text-nColorOrange">
              Privacy Policy
            </div>
          </div>
          <div id="menu-account">
            <button
              class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput"
              @click.prevent="signOut()"
            >
              Sign out
            </button>
          </div>
        </div>
        <div class="bg-nColorTextInput rounded-3xl text-white p-6 m-4">
          <h2 class="text-2xl font-bold">Introduction</h2>
          <p class="text-nColorLightGray">
            playda.io ("we," "our," or "us") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your personal information when you use our app. By
            using playda.io, you consent to the practices described in this
            policy.
          </p>
          <h2 class="text-2xl font-bold mt-6">Information We Collect</h2>
          <p class="text-nColorLightGray">
            Information You Provide: We may collect personal information you
            voluntarily provide, such as your name, mobile number, email
            address, and other details when you play or register or use certain
            features. Automatically Collected Information: We may automatically
            collect certain information, including but not limited to your
            device's IP address, operating system, browser type, and usage
            information. Cookies and Tracking Technologies: We may use cookies
            and similar tracking technologies to collect information about your
            use of playda.io. You can disable cookies through your browser
            settings.
          </p>
          <h2 class="text-2xl font-bold mt-6">How We Use Your Information</h2>
          <p class="text-nColorLightGray">
            To Provide Our Services: We use your information to provide and
            improve our services, personalize your experience, and communicate
            with you. To Send Promotional Information: We may send you
            promotional materials, offers, and news about playda.io if you've
            opted in to receive them. To Ensure Security: We use your
            information to maintain the security and integrity of our app, as
            well as to prevent fraud and monitor for security risks.
          </p>
          <h2 class="text-2xl font-bold mt-6">Sharing Your Information</h2>
          <p class="text-nColorLightGray">
            We do not sell, trade, or transfer your personal information to
            third parties. However, we may share your information in the
            following circumstances: With Service Providers: We may share your
            information with trusted third-party service providers and partner
            brands and businesses who assist us in delivering our services and
            provide the user rewards via freebies and vouchers. Legal
            Compliance: We may disclose your information to comply with legal
            obligations, respond to lawful requests, protect our rights,
            privacy, safety, or property.
          </p>
          <h2 class="text-2xl font-bold">Your Data Rights</h2>
          <p class="text-nColorLightGray">
            You have the right to: Access the personal information we hold about
            you. b. Correct inaccuracies in your information. c. Delete your
            data. d. Withdraw consent for marketing communications. e. Lodge a
            complaint with a relevant data protection authority.
          </p>
          <h2 class="text-2xl font-bold mt-6">Data Security</h2>
          <p class="text-nColorLightGray">
            We implement reasonable security measures to protect your
            information from unauthorized access or disclosure. However, no data
            transmission or storage can be entirely secure. Therefore, we cannot
            guarantee absolute security.
          </p>
          <h2 class="text-2xl font-bold mt-6">Children's Privacy</h2>
          <p class="text-nColorLightGray">
            playda.io is not intended for users under the age of 13. We do not
            knowingly collect or maintain information from children under 13. If
            we discover that we have unintentionally collected data from a child
            under 13, we will promptly delete it.
          </p>
          <h2 class="text-2xl font-bold mt-6">
            Changes to This Privacy Policy
          </h2>
          <p class="text-nColorLightGray">
            We may update this Privacy Policy to reflect changes in our
            practices or for other operational, legal, or regulatory reasons. We
            will notify you of any material changes by posting the updated
            Privacy Policy on our app.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Dashboard/DesktopMenu.vue";

export default {
  name: "PrivacyPolicy",
  components: { Menu },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.content {
  height: 100dvh;
}
</style>
